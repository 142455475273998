export enum ConversionsAPIEventNames {
  StartQuiz = 'start_quiz',
  EndQuiz = 'end_quiz',
  QuestionAnswered = 'question_answered',
  Lead = 'Lead',
  Purchase = 'Purchase',
  Checkout = 'checkout',
  PageView = 'PageView',
  InitiateCheckout = 'InitiateCheckout',
  PurchaseUpsellLifetime = 'purchaseUpsellLifetime',
  SubmitFormTT = 'SubmitForm',
  CompletePayment = 'CompletePayment',
  Lead_SC = 'SIGN_UP',
  Purchase_SC = 'PURCHASE',
  PageView_SC = 'PAGE_VIEW',
  Initiate_Checkout_SC = 'START_CHECKOUT',
}
