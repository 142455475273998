import React, { FC, HTMLAttributes } from 'react';
import styled from 'styled-components';

import { Text } from 'components';
import { OrderSummarySubItem } from 'types/checkout';
import { getLocalisedProduct } from 'utils/localization';
import { Product } from 'types/product';

interface PaymentDialogOrderSummaryProps {
  selectedProduct: Product | null;
  subItems?: OrderSummarySubItem[];
}

const Container = styled.div`
  padding: 1rem;
  border: 1px solid ${({ theme }) => theme.colors.lighterGrey};
  border-radius: 0.5rem;
  background-color: ${({ theme }) => theme.colors.white};
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  height: fit-content;
  width: 100%;
  max-width: 24.563rem;
`;

const OrderSummaryItemContainer = styled.div<{ paddingBottom?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: ${({ paddingBottom }) => (!paddingBottom ? 0 : 0.75)}rem;
`;

const Divider = styled.div`
  height: 1px;
  background-color: ${({ theme }) => theme.colors.light100};
  /* margin: 1rem 0 1rem 0; */
`;

const ProductLabel = styled(Text)`
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.125rem;
  color: ${({ theme }) => theme.colors.black};
`;

const DiscountLabel = styled(Text)`
  color: #dc4b4b;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.125rem;
`;

const TotalLabel = styled(Text)`
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.125rem;
  color: ${({ theme }) => theme.colors.black};
`;

const PaymentDialogOrderSummary: FC<
  PaymentDialogOrderSummaryProps & HTMLAttributes<HTMLDivElement>
> = ({ selectedProduct, subItems, ...props }) => {
  if (!selectedProduct) {
    return null;
  }

  const product = getLocalisedProduct(selectedProduct);
  const trialAmount = product?.trialAmount;
  const trialOldAmount = product?.trialOldAmount;
  const savings = trialOldAmount! - trialAmount!;
  const percentage = (savings / trialOldAmount!) * 100;

  return (
    <Container {...props}>
      <OrderSummaryItemContainer>
        <ProductLabel type="bodyM">
          Moongrade {selectedProduct?.name}
        </ProductLabel>
        <ProductLabel type="bodyM">
          {product?.currency}
          {product?.trialOldAmount?.toFixed(2)}
        </ProductLabel>
      </OrderSummaryItemContainer>
      {product?.hasTrials && (
        <OrderSummaryItemContainer>
          <DiscountLabel type="bodyM700" color="red80">
            Discount (-{percentage.toFixed(0)}%)
          </DiscountLabel>
          <DiscountLabel type="bodyM" color="red80">
            -{product?.currency}
            {(product?.trialOldAmount! - product?.trialAmount!).toFixed(2)}
          </DiscountLabel>
        </OrderSummaryItemContainer>
      )}
      {(subItems || []).map((subItem, index) => (
        <OrderSummaryItemContainer key={index}>
          <Text type="bodyM">{subItem.title}</Text>
          {subItem.value && (
            <Text type="bodyM">
              <span dangerouslySetInnerHTML={{ __html: subItem.value }} />
            </Text>
          )}
        </OrderSummaryItemContainer>
      ))}
      <Divider />
      <OrderSummaryItemContainer>
        <TotalLabel type="body700">Total:</TotalLabel>
        <TotalLabel type="h4700">
          {product?.currency}
          {product?.trialAmount?.toFixed(2)}
        </TotalLabel>
      </OrderSummaryItemContainer>
    </Container>
  );
};

export default PaymentDialogOrderSummary;
