import React from 'react';
import type { FC } from 'react';
import styled from 'styled-components';
import { tablet } from 'styles/breakpoints';
import Text from './Text';

interface DisclaimerProps {
  text?: string;
  bgHex?: string;
  copyright?: string;
}

const Container = styled.div<{ bgColor?: string }>`
  display: flex;
  max-height: 4.5rem;
  height: 100%;
  padding: 1.5rem 1rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${({ bgColor }) => bgColor};
`;

const DisclaimerText = styled(Text)`
  color: ${({ theme }) => theme.colors.black};
  text-align: center;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 300;
  line-height: 140%;
`;

const Disclaimer: FC<DisclaimerProps> = ({ bgHex, text, copyright }) => (
  <Container bgColor={bgHex}>
    <DisclaimerText>
      {copyright.replace('${year}', new Date().getFullYear().toString())}
    </DisclaimerText>
  </Container>
);

export default Disclaimer;
