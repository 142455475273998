import React, { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { use100vh } from 'react-div-100vh';

import { Seo } from 'components';
import PaymentDialog from './components/OldPaymentDialog';
import { AppState } from 'state/types';
import { useRouter } from 'apis/history';
import Tracking from 'utils/tracking';
import { config } from '../../../config';
import { Events } from 'utils/events';
import axios from 'axios';
import {
  useCheckoutData,
  useOnboardingCheckoutData,
  usePageView,
} from 'utils/hooks';
import { getLocalisedProduct } from 'utils/localization';
import { handleError } from 'utils/error';
import styled from 'styled-components';
import { store } from 'index';
import { Quiz, QuizKey } from 'types/quiz';
import Header from 'components/Header';
import { table } from 'console';
import { tablet } from 'styles/breakpoints';
import Cards from 'assets/icons/cards.svg';

interface OldPaymentProps {
  handleClose: () => void;
  isPaymentComponentVisible?: boolean;
}

const PaymentDialogContainer = styled.div`
  width: 100%;
  justify-content: center;
  display: flex;
  /* background-color: ${({ theme }) => theme.colors.lighterGrey}; */
  min-height: calc(100vh - 202px);

  @media ${tablet} {
    /* height: unset; */
    min-height: calc(118vh);
  }
`;

const OldPayments: FC<OldPaymentProps> = ({
  handleClose,
  isPaymentComponentVisible,
}) => {
  const { code, selected_plan, user, quiz_answers, geolocation } = useSelector(
    (state: AppState) => state.user,
  );
  const quiz =
    new URLSearchParams(location.search).get(QuizKey.Qz) ?? Quiz.Main;

  const { goToRegister, navigateBack, goToDiscount, goToUpgrade } = useRouter();
  sessionStorage.setItem('onboarding-checkout', 'true');
  const { data } = useCheckoutData();

  const height = use100vh() ?? 0;

  const stateSelectedPlan = store.getState().user.selected_plan;

  useEffect(() => {
    if (!code || !isPaymentComponentVisible) {
      return;
    }
    Tracking.logEvent({
      event: 'payment-window-clicks',
      funnel: quiz,
      widget: 'payment-window-turned-on',
      product: localisedProduct?.key,
    });
    Tracking.logEvent({
      event: 'email_left',
      client_code: code,
    });
  }, [code, isPaymentComponentVisible]);

  const localisedProduct = getLocalisedProduct(selected_plan!);

  const createHyrosOrder = async ({ orderId }: { orderId: number }) => {
    try {
      if (config?.ENV === 'development') {
        return;
      }
      const localisedProduct = getLocalisedProduct(stateSelectedPlan);

      const productVisiblePrice = localisedProduct.hasTrial
        ? localisedProduct.discountedTrialPrice
        : localisedProduct.finalPrice;
    } catch (e) {
      handleError(e);
    }
  };

  const handleSuccess = async (data: any) => {
    const localisedProduct = getLocalisedProduct(
      store.getState().user.selected_plan,
    );

    const productVisiblePrice = localisedProduct?.hasTrials
      ? localisedProduct.trialAmount
      : localisedProduct?.finalPrice;

    try {
      const orderId = String(data?.order_id ?? '');
      const eventData = {
        code: code ?? '',
        email: user?.email,
        location: geolocation?.iso_country?.toLocaleLowerCase(),
        orderId,
        data: {
          currencyCode: localisedProduct?.currency,
          paymentMethod: '',
          transactionId: orderId,
          transactionTotal: parseFloat(productVisiblePrice),
          transactionAffiliation: '',
          transactionProducts: [
            {
              sku: localisedProduct?.sku,
              name: localisedProduct?.name,
              category: 'Subscription',
              price: productVisiblePrice,
              quantity: 1,
            },
          ],
        },
      };

      Events.paymentSuccessful(eventData);

      createHyrosOrder({ orderId: Number(orderId) });

      try {
        const funnelData = {
          code: code ?? '',
          quizAnswers: quiz_answers ?? {},
          selectedPlan: store.getState().user.selected_plan ?? {},
          quiz,
        };
        await axios.post(config.REACT_APP_FIREBASE_USER_HAS_PAYED_URL, {
          email: user?.email,
          funnel: funnelData,
        });
      } catch (e) {
        handleError(e);
      }
    } catch (e) {
      handleError(e);
    }

    goToRegister();
    // goToUpgrade('upgrade/02');
  };

  const handleOnClose = () => {
    Tracking.logEvent({
      event: 'payment-window-clicks',
      funnel: quiz,
      widget: 'payment-window-turned-off',
      product: localisedProduct?.key,
    });
    if (data?.discountAvailable) {
      return goToDiscount();
    }
    handleClose();
    // navigateBack();
  };

  // Fixes screen jumping when entering credit card number
  useEffect(() => {
    const bodyWrapper = document.getElementById('body-wrapper');
    if (!bodyWrapper) {
      return;
    }

    bodyWrapper.style.height = `${height}px`;

    return () => {
      bodyWrapper.style.height = '';
    };
  }, [height]);

  return (
    <>
      {/* <Header hasDivider={false} hasShadow={true} /> */}
      <PaymentDialogContainer>
        <PaymentDialog
          selectedProduct={selected_plan}
          isOpen={true}
          onRequestClose={handleOnClose}
          onSuccess={handleSuccess}
          orderSummarySubItems={data?.orderSummarySubItems}
          testTitle={data?.testTitle}
        />
      </PaymentDialogContainer>
      <Disclaimer>
        {data?.paymentDisclaimer.replace(
          '${year}',
          new Date().getFullYear().toString(),
        )}
      </Disclaimer>
    </>
  );
};

export default OldPayments;

const Disclaimer = styled.div`
  display: flex;
  padding: 1.5rem 1rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.lighterGrey};
  color: ${({ theme }) => theme.colors.black};
  text-align: center;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 300;
  line-height: 140%;

  @media ${tablet} {
    font-size: 0.75rem;
    line-height: 1.125rem;
  }
`;

const SafeCheckout = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  align-items: center;
  width: 100%;
  margin-bottom: 2rem;

  p {
    color: #8d8d8d;
    font-size: 0.625rem;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 0.9375rem */
    letter-spacing: -0.00688rem;

    b {
      font-weight: 600;
    }
  }

  @media ${tablet} {
    margin-bottom: 1rem;
  }
`;
