import { Action, AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { GeolocationModel, LeadModel } from '@frontend/api-wrapper';
import { handleError } from 'utils/error';
import { Api } from 'apis/api';
import {
  getLocalisedProduct,
  getValidatedIsoCountry,
} from 'utils/localization';

import {
  setCode,
  setErrorMsg,
  setGeolocation,
  setPossibleHairTypes,
  setQuizAnswers,
  setQuizFlowAnswers,
  setSelectedPlan,
  setSelectedPlans,
  setSelectedPlansOptions,
  setShippingAddress,
  setSubscriptionConfig,
  setTermsAndConditions,
  setUpsellProducts,
  setUser,
  setUserIp,
} from './actions';
import { IUserState } from './types';
import axios from 'axios';

export const updateSubscriptionConfig =
  (subscription_config: IUserState['subscription_config']) =>
  (dispatch: ThunkDispatch<{}, void, AnyAction>) =>
    dispatch(setSubscriptionConfig(subscription_config));

export const updateSelectedPlansOptions =
  (selected_plans_options: IUserState['selected_plans_options']) =>
  (dispatch: ThunkDispatch<{}, void, AnyAction>) =>
    dispatch(setSelectedPlansOptions(selected_plans_options));

export const updateShippingAddress =
  (shippingAddres: IUserState['shipping_address']) =>
  (dispatch: ThunkDispatch<{}, void, AnyAction>) =>
    dispatch(setShippingAddress(shippingAddres));

export const updateTermsAndConditions =
  (tAndC: IUserState['termsAndConditions']) =>
  (dispatch: ThunkDispatch<{}, void, AnyAction>) =>
    dispatch(setTermsAndConditions(tAndC));

export const updatePossibleHairTypes =
  (possibleHairTypes: IUserState['possibleHairTypes']) =>
  (dispatch: ThunkDispatch<{}, void, AnyAction>) =>
    dispatch(setPossibleHairTypes(possibleHairTypes));

export const updateQuizAnswers =
  (answers: IUserState['quiz_answers']) =>
  (dispatch: ThunkDispatch<{}, void, AnyAction>) => {
    const queryParams = new URLSearchParams(window.location.search);
    const quizToken = queryParams.get('steps');
    const codeParam = window.location.pathname?.split('/')?.pop();
    const pathCode = queryParams.get('code') || codeParam;

    if (quizToken) {
      const encodedState = queryParams.get('steps');
      if (encodedState) {
        try {
          const decodedState = decodeURIComponent(atob(encodedState));
          const parsedState = JSON.parse(decodedState);
          const newState = JSON.stringify({ ...parsedState, ...answers });
          const newEncodedState = encodeURIComponent(newState);
          queryParams.set('steps', btoa(newEncodedState));
          const newSearchParams = queryParams.toString();
          const newUrl = `${window.location.pathname}?${newSearchParams}${window.location.hash}`;
          window.history.replaceState(null, '', newUrl);
        } catch (error) {
          console.error('Error parsing state from URL:', error);
        }
      }
    }

    if (!quizToken && !(pathCode && pathCode.length === 32)) {
      const serializedState = JSON.stringify(answers);
      const encodedState = encodeURIComponent(serializedState);

      queryParams.set('steps', btoa(encodedState));
      const newSearchParams = queryParams.toString();

      const newUrl = `${window.location.pathname}?${newSearchParams}${window.location.hash}`;
      window.history.replaceState(null, '', newUrl);
    }
    dispatch(setQuizAnswers(answers));
  };

export const resetQuizAnswers =
  (answers: IUserState['quiz_answers']) =>
  (dispatch: ThunkDispatch<{}, void, AnyAction>) =>
    dispatch(resetQuizAnswers({}));

export const updateQuizFlowAnswers =
  (quiz_flow_answers: IUserState['quiz_flow_answers']) =>
  (dispatch: ThunkDispatch<[], void, AnyAction>) =>
    dispatch(setQuizFlowAnswers(quiz_flow_answers));

export const updateUser =
  (user: IUserState['user']) =>
  (dispatch: ThunkDispatch<{}, void, AnyAction>) =>
    dispatch(setUser(user));

export const updateCode =
  (code: IUserState['code']) =>
  (dispatch: ThunkDispatch<{}, void, Action<IUserState['code']>>) =>
    dispatch(setCode(code));

export const updateSelectedPlan =
  (product: IUserState['selected_plan']) =>
  (dispatch: ThunkDispatch<{}, void, AnyAction>) => {
    if (product && typeof (window as any).kiloCheckout !== 'undefined') {
      const price = getLocalisedProduct(product).finalPrice;
      const kiloProduct = new (window as any).KiloProduct(
        product.key,
        product.name,
        product.finalPrice,
      );
      (window as any).kiloCheckout.selectProduct(kiloProduct);
    }

    dispatch(setSelectedPlan(product));
  };

export const updateSelectedPlans =
  (products: IUserState['selected_plans']) =>
  (dispatch: ThunkDispatch<{}, void, AnyAction>) => {
    if (products && typeof (window as any).kiloCheckout !== 'undefined') {
      const localisedProducs = products?.map(product =>
        getLocalisedProduct(product),
      );

      const calculateFullPrice = products => {
        let fullPrice = 0;

        for (let i = 0; i < products.length; i++) {
          fullPrice = fullPrice + Number(products[i].finalPrice);
        }

        return fullPrice;
      };

      const kiloOrderObj = {
        amount: Number(calculateFullPrice(localisedProducs).toFixed(2)),
        amount_in_cents: Math.floor(calculateFullPrice(localisedProducs) * 100),
        title: 'Moongrade',
        products: localisedProducs?.map(product => ({
          key: product.key,
          quantity: 1,
        })),
      };
      const kiloProduct = new (window as any).KiloOrder(kiloOrderObj);

      (window as any).kiloCheckout.selectOrder(kiloProduct);
    }

    dispatch(setSelectedPlans(products));
  };

export const updateUpsellProducts =
  (products: IUserState['upsell_products']) =>
  (dispatch: ThunkDispatch<{}, void, AnyAction>) =>
    dispatch(setUpsellProducts(products));

export const updateErrorMsg =
  (errorMsg: IUserState['error_msg']) =>
  (dispatch: ThunkDispatch<{}, void, AnyAction>) =>
    dispatch(setErrorMsg(errorMsg));

export const createLead =
  (data: LeadModel) => async (dispatch: ThunkDispatch<{}, void, AnyAction>) => {
    try {
      const { code } = await Api.createLead(data);
      dispatch(updateCode(code));
      return code; // Return the code so it can be awaited
    } catch (e: any) {
      handleError(e);
      throw e; // Ensure error is propagated to the caller
    }
  };

export const updateLead =
  (code: string, data: LeadModel) =>
  (dispatch: ThunkDispatch<{}, void, AnyAction>) =>
    Api.updateLead(code, data).catch((e: any) => {
      handleError(e);
      throw e;
    });

// export const fetchGeolocation =
//   () => (dispatch: ThunkDispatch<{}, void, AnyAction>) =>
//     Api.getUserGeolaction()
//       .then((geolocation: GeolocationModel) => {
//         if (!geolocation.iso_country) return;

//         const urlParams = new URLSearchParams(window.location.search);
//         const isoCountry = getValidatedIsoCountry(geolocation.iso_country);

//         if (isoCountry !== urlParams.get('locale')) {
//           urlParams.set('locale', isoCountry);
//           window.location.search = urlParams.toString();
//         }

//         dispatch(setGeolocation(geolocation));
//       })
//       .catch(handleError);

export const fetchGeolocation =
  () => (dispatch: ThunkDispatch<{}, void, AnyAction>) =>
    new Promise<void>((resolve, reject) => {
      Api.getUserGeolaction()
        .then((geolocation: GeolocationModel) => {
          axios
            .get('https://api64.ipify.org?format=json')
            .then(response => {
              const userIp = response.data?.ip;
              dispatch(setUserIp(userIp));
            })
            .catch(ipError => {
              console.error('Error fetching IP address:', ipError);
            });
          if (typeof window !== 'undefined') {
            if (!geolocation.iso_country) {
              resolve();
              return;
            }

            const urlParams = new URLSearchParams(window?.location.search);
            const isoCountry = getValidatedIsoCountry(geolocation.iso_country);
            localStorage.setItem('userLocale', isoCountry);

            const currentLocale = urlParams.get('locale');
            if (currentLocale !== isoCountry) {
              if (currentLocale) {
                urlParams.set('locale', isoCountry);
              } else {
                urlParams.append('locale', isoCountry);
              }

              const newSearch = urlParams.toString();
              window?.history.replaceState(
                {},
                '',
                `${window?.location.pathname}?${newSearch}`,
              );
            }

            dispatch(setGeolocation(geolocation));
            resolve(); // Resolve the promise after dispatching
          }
        })
        .catch(error => {
          handleError(error);
          reject(error); // Reject the promise on error
        });
    });

export const fetchUser =
  () => (dispatch: ThunkDispatch<{}, void, AnyAction>) => {
    if (typeof window !== 'undefined') {
      const params = new URLSearchParams(window.location.search);
      const codeParam = window.location.pathname?.split('/')?.pop();
      const pathCode = params.get('code') || codeParam;
      const quizCode = params.get('steps');

      if (pathCode && pathCode.length === 32) {
        Api.fetchLead<{ email: string; quiz_answers: unknown; orders: any }>(
          pathCode,
        )
          .then(({ email, code, quiz_answers, client }) => {
            if (client?.orders?.length > 0) {
              Api.fetchProducts().then(funnelProducts => {
                const products = client.orders.flatMap(item => {
                  const productItems = item.items.map(prod => prod.product);
                  return productItems;
                });

                const basicProducts = products
                  .map(product =>
                    funnelProducts.find(
                      fp => fp.key === product.key && !product.is_upsell,
                    ),
                  )
                  .filter(item => item);
                const uspellProducts = products
                  .map(product =>
                    funnelProducts.find(
                      fp => fp.key === product.key && product.is_upsell,
                    ),
                  )
                  .filter(item => item);

                dispatch(updateSelectedPlans(basicProducts));
                dispatch(updateUpsellProducts(uspellProducts));
              });
            }

            if (email) {
              dispatch(updateUser({ email, password: '' }));
            }

            if (quiz_answers) {
              dispatch(updateQuizAnswers(quiz_answers));
            }

            dispatch(updateCode(code));
          })
          .catch(handleError);
      }

      if (quizCode && !(pathCode && pathCode.length === 32)) {
        const decodedQuizState = decodeURIComponent(atob(quizCode));
        const parsedState = JSON.parse(decodedQuizState);
        dispatch(updateQuizAnswers(parsedState));
      }
    }
  };
